.about__section-item {
  column-gap: 3rem;
}

.about__section-item p  {
  color: #fffffe;
  font-size: 1.1rem;
}
.about__section-item p i {
  color: #f9a826;
  font-size: 1.1rem;
}
.about-container-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 992px) {
  .about__section-content h2 {
    font-size: 1.5rem;
  }
  .about__section-item {
    column-gap: 3rem;
  }
  .about__section-item p {
    font-size: 0.6rem;
    margin-bottom: 0;
  }

  .section__description i {
    font-size: 0.8rem !important;
  }
}

@media only screen and (max-width: 768px) {
  .about__img {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .about__section {
    margin-top: 280px;
  }
}
.about__img{
  max-width: 500px;
}