.social__link-icon {
  text-decoration: none;

  color: #ffffff;
  font-weight: 500;
  font-size: 1.2rem;
}

.contact__btn {
  padding: 7px 15px;
  background: #252526;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 5px;
}
.contact__info h6{
  color: white;
}
.contact-title{
  color: white;
}
@media only screen and (max-width: 768px) {
  .contact__btn {
    margin-bottom: 40px;
    font-size: 0.8rem;
  }
}
